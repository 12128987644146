// Import package from Soil plugin example.
import $ from "@modules/jquery";

$(function () {
  mtmAccordionEvents();

  $("nav#menu")
    .find("*")
    .each(function () {
      $(this).removeAttr("role aria-haspopup aria-label");
    });

  if (typeof maptilersdk !== "undefined") {
    function getImageUrl() {
      const origin = window.location.origin;
      const image = "storage/dist/ggdafspraak/images/marker-eigenlocatie.svg";

      if (origin.includes(".test")) {
        return `https://ggdafspraak.test/wp-content/${image}`;
      }

      if (origin.includes("dev.ggdafspraak.nl")) {
        return `https://dev.ggdafspraak.nl/inc/${image}`;
      }

      if (origin.includes("acc.ggdafspraak.nl")) {
        return `https://acc.ggdafspraak.nl/inc/${image}`;
      }

      return `https://ggdafspraak.nl/inc/${image}`;
    }

    function svgStringToImageSrc(svgString) {
      return (
        "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svgString)
      );
    }

    const currentLanguage = document
      .getElementsByTagName("html")[0]
      .getAttribute("lang")
      .substring(0, 2);

    const ownLocation = new Proxy({ coordinates: "" }, {});

    maptilersdk.config.apiKey = MAP.maptiler_api_key;
    maptilersdk.config.primaryLanguage =
      currentLanguage === "nl"
        ? maptilersdk.Language.DUTCH
        : maptilersdk.Language.ENGLISH;
    const map = new maptilersdk.Map({
      container: "map",
      style: maptilersdk.MapStyle.STREETS,
      center: [4.895168, 52.370216],
      zoom: 6.5,
    });
    map.on("load", async function () {
      const locationImage = new Image(34, 45);
      locationImage.onload = () => {
        map.addImage("location-marker", locationImage);
      };
      const locationSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.4 75">
  <g style="transform-origin:104.088px 26.4213px">
    <path fill="#000199" d="M34.783.732c4.2.997 7.928 2.832 11.218 5.526 4.997 4.09 8.25 9.286 9.756 15.571 1.237 5.16.757 10.157-1.185 15.086-2.525 6.41-6.117 12.208-10 17.846-4.063 5.897-8.506 11.498-13.174 16.924-.762.886-1.538 1.76-2.302 2.645-.762.885-.935.903-1.716-.01-2.047-2.396-4.099-4.788-6.108-7.215-4.41-5.326-8.516-10.877-12.254-16.695-3.099-4.822-5.923-9.795-7.72-15.263C-1.41 26.92.263 19.293 4.963 12.288 9.338 5.77 15.55 1.784 23.274.435a29.132 29.132 0 0 1 11.51.297M16.315 39.204c-.09.286-.32.617-.243.847.123.371.385.842.704.962.347.13.945.05 1.226-.186.894-.75 1.673-1.636 2.536-2.425a1.58 1.58 0 0 1 .925-.389 55.028 55.028 0 0 1 3.585-.01c.923.025 1.668-.313 2.303-.948 2.924-2.924 5.848-5.849 8.77-8.776.144-.144.264-.312.341-.404l-7.047-7.045-3.01 2.924c.851.841 1.715 1.7 2.587 2.55.368.36.54.778.168 1.188-.407.45-.843.292-1.228-.104-.862-.885-1.73-1.764-2.542-2.593l-2.13 2.12c.867.85 1.775 1.7 2.625 2.603.19.201.264.601.23.89-.023.18-.355.456-.539.45-.272-.01-.588-.18-.793-.377-.886-.853-1.74-1.74-2.6-2.613-.82.808-1.629 1.467-2.256 2.27-.38.486-.628 1.18-.68 1.802-.104 1.235-.002 2.485-.05 3.726-.013.34-.178.742-.407.995-.77.848-1.602 1.642-2.475 2.543m19.602-22.746c-.729 1.07.24 1.624.644 2.13l-1.767 1.922c-.81-.82-1.607-1.643-2.42-2.45-.615-.61-1.317-.663-1.83-.162-.57.554-.533 1.205.13 1.876.905.92 1.823 1.826 2.735 2.74 1.352 1.35 2.696 2.71 4.058 4.052.631.62 1.29.635 1.824.092.478-.486.454-1.222-.105-1.782-.859-.86-1.743-1.694-2.507-2.433l1.766-1.958.638.602c.604.572 1.245.6 1.765.075.508-.512.495-1.182-.08-1.77a170.677 170.677 0 0 0-2.998-2.984c-.58-.563-1.09-.556-1.853.05z"/>
    <path fill="#FDFDFE" d="M16.347 39.161c.841-.858 1.672-1.652 2.443-2.5.23-.252.394-.654.407-.995.048-1.24-.054-2.49.05-3.726.052-.622.298-1.316.68-1.803.627-.802 1.434-1.46 2.255-2.269.862.872 1.715 1.76 2.601 2.613.205.197.521.366.793.376.184.007.516-.27.538-.449.035-.289-.04-.689-.229-.89-.85-.904-1.758-1.754-2.624-2.604l2.13-2.119c.811.83 1.68 1.708 2.541 2.593.385.396.821.554 1.228.104.371-.41.2-.828-.168-1.187-.872-.85-1.736-1.71-2.588-2.55l3.011-2.925 7.047 7.045c-.077.092-.198.26-.342.404-2.92 2.927-5.845 5.852-8.77 8.776-.634.635-1.379.973-2.302.948a54.976 54.976 0 0 0-3.585.01c-.316.012-.69.174-.925.39-.863.788-1.642 1.673-2.536 2.424-.28.236-.879.317-1.226.186-.32-.12-.58-.59-.704-.962-.077-.23.152-.56.276-.89zM35.96 16.417c.72-.565 1.232-.572 1.811-.01 1.011.983 2.01 1.977 2.997 2.984.576.59.59 1.26.081 1.77-.52.526-1.16.498-1.765-.074l-.637-.602-1.767 1.958c.764.74 1.65 1.573 2.507 2.433.56.56.583 1.296.105 1.781-.534.544-1.193.53-1.824-.09-1.362-1.343-2.706-2.702-4.058-4.054-.912-.913-1.83-1.82-2.736-2.739-.662-.67-.698-1.32-.13-1.876.514-.5 1.216-.449 1.831.162.813.807 1.61 1.63 2.42 2.45l1.767-1.922c-.404-.506-1.373-1.06-.602-2.17z"/>
  </g>
</svg>`;
      locationImage.src = svgStringToImageSrc(locationSvg);

      const ownMarkerDiv = document.createElement("div");
      ownMarkerDiv.className = "own-marker";
      ownMarkerDiv.style.backgroundImage = `url(${getImageUrl()})`;
      ownMarkerDiv.style.backgroundRepeat = `no-repeat`;
      ownMarkerDiv.style.width = "34px";
      ownMarkerDiv.style.height = "45px";

      const gc = new maptilersdkMaptilerGeocoder.GeocodingControl({
        language: "nl",
        country: "nl",
        limit: 10,
        types: ["postal_code"],
        placeholder: WP[currentLanguage].map_enter_your_postcode,
        errorMessage: WP[currentLanguage].map_something_went_wrong,
        noResultsMessage: WP[currentLanguage].map_no_results,
        marker: {
          element: ownMarkerDiv,
        },
        showResultMarkers: false,
        flyTo: {
          zoom: 11,
        },
        proximity: null,
        minLength: 1,
      });
      gc.addEventListener("pick", (e) => {
        if (e.detail?.center?.[0]) {
          ownLocation.coordinates =
            e.detail.center[1] + "," + e.detail.center[0];
        }
      });
      gc.addEventListener("queryChange", (e) => {
        if (e.detail === "") {
          ownLocation.coordinates = "";
        }
      });
      map.addControl(gc, "top-left");

      map.addSource("locations", MAP.source);

      map.addLayer({
        id: "clusters",
        type: "circle",
        source: "locations",
        filter: ["has", "point_count"],
        paint: {
          // Use step expressions (https://docs.maptiler.com/gl-style-specification/expressions/#step)
          // with three steps to implement three types of circles:
          //   * 20px circles when point count is less than 100
          //   * 30px circles when point count is between 100 and 750
          //   * 40px circles when point count is greater than or equal to 750
          "circle-color": [
            "step",
            ["get", "point_count"],
            "#009",
            100,
            "#009",
            750,
            "#009",
          ],
          "circle-radius": [
            "step",
            ["get", "point_count"],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      });

      map.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "locations",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["Montserrat Bold"],
          "text-size": 12,
        },
        paint: {
          "text-color": "#ffffff",
        },
      });

      map.addLayer({
        id: "unclustered-point",
        // type: "circle",
        type: "symbol",
        source: "locations",
        filter: ["!", ["has", "point_count"]],
        // paint: {
        //   "circle-color": "#009",
        //   "circle-radius": 6,
        //   "circle-stroke-width": 1,
        //   "circle-stroke-color": "#fff",
        // },
        layout: {
          "icon-image": "location-marker",
          "icon-size": 1.0,
          "icon-offset": [0, -22],
        },
      });

      map.on("click", "clusters", async function (e) {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ["clusters"],
        });
        const clusterId = features[0].properties.cluster_id;
        const zoom = await map
          .getSource("locations")
          .getClusterExpansionZoom(clusterId);
        map.easeTo({
          center: features[0].geometry.coordinates,
          zoom,
        });
      });

      map.on("click", "unclustered-point", function (e) {
        console.log(e.features[0]);
        const routeLink = `<a href="https://www.google.nl/maps/dir/${
          ownLocation.coordinates
        }/${
          e.features[0].properties.lat + "," + e.features[0].properties.lng
        }" target="_blank">${WP[currentLanguage].map_route}</a>`;
        const description = e.features[0].properties.description;
        const descriptionWithRoute = description.replace(
          "{ROUTE_PLACEHOLDER}",
          routeLink
        );
        const infoCard = document.querySelector(".map-container .info-card");
        infoCard.style.display = "flex";
        infoCard.innerHTML = descriptionWithRoute;

        map.easeTo({
          center: e.features[0].geometry.coordinates,
        });
      });

      map.on("mouseenter", "clusters", function () {
        map.getCanvas().style.cursor = "pointer";
      });
      map.on("mouseleave", "clusters", function () {
        map.getCanvas().style.cursor = "";
      });
      map.on("mouseenter", "unclustered-point", function () {
        map.getCanvas().style.cursor = "pointer";
      });
      map.on("mouseleave", "unclustered-point", function () {
        map.getCanvas().style.cursor = "";
      });

      // const locations = MAP.locations;
      // for (const location of locations) {
      //   const marker = new maptilersdk.Marker()
      //     .setLngLat([location.lng, location.lat])
      //     .addTo(map);
      //   marker.getElement().addEventListener("click", () => {
      //     const description = location.description;
      //     const infoCard = document.querySelector(".map-container .info-card");

      //     infoCard.style.display = "flex";
      //     infoCard.innerHTML = description;
      //   });
      // }

      $(".mapboxgl-ctrl-geocoder input").on("focus", function () {
        const infoCard = document.querySelector(".map-container .info-card");
        infoCard.style.display = "none";
        infoCard.innerHTML = "";
      });

      const ownMarkerObserver = new MutationObserver(function (mutations) {
        const ownMarker = $(".own-marker");
        if (document.contains(ownMarker[0])) {
          console.log("It's in the DOM!");
          ownMarker.on("click", function (e) {
            const infoCard = document.querySelector(
              ".map-container .info-card"
            );
            infoCard.style.display = "flex";
            infoCard.innerHTML = `<div class="space-y-4 text-base/[1.6]">
       <span class="name h4">${WP[currentLanguage].map_entered_location}</span>
       </div>`;
          });
        }
      });
      ownMarkerObserver.observe(document, {
        attributes: false,
        childList: true,
        characterData: false,
        subtree: true,
      });
    });
  }
});

$(window).on("changed.zf.mediaquery", function (e, target) {
  mtmAccordionEvents();
});

// Matomo integration
// https://docs.google.com/presentation/d/1PaHlZgLTvubm2KWSuBLAUS0By_2TzbKy/edit#slide=id.gdf2db855ce_0_55
// Slide 5
function mtmAccordionEvents() {
  $("[data-accordion]")
    .off("down.zf.accordion")
    .on("down.zf.accordion", function (e, target) {
      var _mtm = window._mtm || [];
      _mtm.push({
        event: "interaction.generic.component.accordion",
        meta: {
          action: "open",
          label: `${target.prev().text().trim()} - ${window.location.href}`,
        },
      });
    });
  $("[data-accordion]")
    .off("up.zf.accordion")
    .on("up.zf.accordion", function (e, target) {
      var _mtm = window._mtm || [];
      _mtm.push({
        event: "interaction.generic.component.accordion",
        meta: {
          action: "close",
          label: `${target.prev().text().trim()} - ${window.location.href}`,
        },
      });
    });
}

// Slide 6
$('a[target="_blank"]').on("click", function (e) {
  const linkTitle = $(this).attr("title") || $(this).text();
  var _mtm = window._mtm || [];
  _mtm.push({
    event: "interaction.generic.component.outboundLinkClick",
    meta: {
      action: `${linkTitle} - ${$(this).attr("href")}`,
      label: window.location.href,
    },
  });
});

// Slide 7
$('a:not([target="_blank"])[href^="http"]').on("click", function (e) {
  const linkTitle = $(this).attr("title") || $(this).text();
  var _mtm = window._mtm || [];
  _mtm.push({
    event: "interaction.generic.component.internalLinkClick",
    meta: {
      action: `${linkTitle} - ${$(this).attr("href")}`,
      label: window.location.href,
    },
  });
});

$(function () {
  if ($("body.search-results").length < 1) {
    return;
  }

  // Slide 8
  const searchTerm = $("input#search-field");
  const searchResultCount = $(".search-results-count .count");

  var _mtm = window._mtm || [];
  _mtm.push({
    event: "interaction.component.searchExecution",
    meta: {
      search_term:
        typeof searchTerm[0] !== "undefined" ? searchTerm[0].value : "-",
      search_result_amount:
        typeof searchResultCount[0] !== "undefined"
          ? parseInt(searchResultCount[0].textContent)
          : 0,
      search_type: "manueel",
    },
  });

  // Slide 9
  const a = $("a.search-result-link");
  a.on("click", function (e) {
    const searchTerm = $("input#search-field");
    const searchResultCount = $(".search-results-count .count");
    const searchResultTitle = $(this).siblings("h4")[0]?.textContent;

    var _mtm = window._mtm || [];
    _mtm.push({
      event: "interaction.component.searchResult",
      meta: {
        search_term:
          typeof searchTerm[0] !== "undefined" ? searchTerm[0].value : "-",
        search_result_title: searchResultTitle,
        search_result_url: $(this).attr("href"),
        search_result_selected: a.index(this) + 1,
        search_result_filter: "geen",
        search_result_page: getPageFromUrl(window.location.href),
        search_result_amount:
          typeof searchResultCount[0] !== "undefined"
            ? parseInt(searchResultCount[0].textContent)
            : 0,
        search_type: "manueel",
      },
    });
  });
});

// Slide 10
$(function () {
  if ($("body.search-no-results").length < 1) {
    return;
  }

  const searchTerm = $("input#search-field");

  var _mtm = window._mtm || [];
  _mtm.push({
    event: "interaction.component.searchNoResults",
    meta: {
      search_term:
        typeof searchTerm[0] !== "undefined" ? searchTerm[0].value : "-",
      search_result_amount: 0,
      search_type: "manueel",
    },
  });
});

// Slide 11
$('a[href*="dl.ggdafspraak.nl"]').on("click", function (e) {
  const linkTitle = $(this).attr("title") || $(this).text();
  var _mtm = window._mtm || [];
  _mtm.push({
    event: "interaction.generic.component.download",
    meta: {
      action: `${linkTitle.trim()} - ${$(this).attr("href")}`,
      label: window.location.href,
    },
  });
});

// Slide 12
// TODO: complete
// var _mtm = window._mtm || [];
// _mtm.push({
//   event: "interaction.generic.component.videoInteraction",
//   meta: {
//     action: "{{videoName}} - {{videoAction}} - {{videoURL}}", // vullen met de videonaam, videoactie (acties kunnen zijn startVideo, videoProgress25%, videoProgress50%, videoProgress75%, videoProgress100% en videoPauze) en de videoURL
//     label: window.location.href,
//   },
// });

// Slide 13
$(document).on("ggd_form_submitted", function () {
  var _mtm = window._mtm || [];
  _mtm.push({
    event: "interaction.generic.component.formSubmit",
    meta: {
      vpv_url:
        window.location.pathname +
        window.location.search +
        window.location.hash,
      action: $("h1")[0].textContent,
      label: window.location.href,
    },
  });
});

// Slide [...]
// if (typeof __flowai_webclient_events !== "undefined") {
//   __flowai_webclient_events.addListener("connected", function () {
//     var _mtm = window._mtm || [];
//     _mtm.push({
//       event: "interaction.generic.component.digitalAssistant",
//       meta: {
//         action: "open",
//         label: window.location.href,
//       },
//     });
//   });
//   __flowai_webclient_events.addListener("disconnected", function () {
//     var _mtm = window._mtm || [];
//     _mtm.push({
//       event: "interaction.generic.component.digitalAssistant",
//       meta: {
//         action: "close",
//         label: window.location.href,
//       },
//     });
//   });
//   __flowai_webclient_events.addListener("send", function (message) {
//     var _mtm = window._mtm || [];
//     _mtm.push({
//       event: "interaction.generic.component.digitalAssistant",
//       meta: {
//         action: "start",
//         label: window.location.href,
//       },
//     });
//   });
// }

function getPageFromUrl(url) {
  const match = url.match(/(?:\/page\/|[?&]paged=)([1-9]\d*)/);
  if (match) {
    return Number(match[1]);
  }
  return 1;
}

function getAllUrlParams(url) {
  // get query string from url (optional) or window
  var queryString = url ? url.split("?")[1] : window.location.search.slice(1);

  // we'll store the parameters here
  var obj = {};

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split("#")[0];

    // split our query string into its component parts
    var arr = queryString.split("&");

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split("=");

      // set parameter name and value (use 'true' if empty)
      var paramName = a[0];
      var paramValue = typeof a[1] === "undefined" ? true : a[1];

      // (optional) keep case consistent
      paramName = paramName.toLowerCase();
      if (typeof paramValue === "string") paramValue = paramValue.toLowerCase();

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {
        // create key if it doesn't exist
        var key = paramName.replace(/\[(\d+)?\]/, "");
        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          var index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === "string") {
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
}

function handleYouTubeEmbed(id) {
  var Embeds = Embeds || {};

  window.onYouTubeIframeAPIReady = function () {
    setTimeout(Embeds[`yt_${id}`].onYouTubeIframeAPIReady, 500);
  };

  Embeds[`yt_${id}`] = {
    interval: "",
    done25: false,
    done50: false,
    done75: false,

    init: function () {
      Embeds[`yt_${id}`].loadApi();
    },

    loadApi: function () {
      var j = document.createElement("script"),
        f = document.getElementsByTagName("script")[0];
      j.src = "//www.youtube.com/iframe_api";
      j.async = true;
      f.parentNode.insertBefore(j, f);
    },

    onYouTubeIframeAPIReady: function () {
      window.YT = window.YT || {};
      if (typeof window.YT.Player === "function") {
        var player = new window.YT.Player(`player-${id}`, {
          width: "640",
          height: "390",
          videoId: id,
          events: {
            onStateChange: Embeds[`yt_${id}`].onPlayerStateChange,
            onError: Embeds[`yt_${id}`].onPlayerError,
            onReady: setTimeout(Embeds[`yt_${id}`].onPlayerReady, 4000),
          },
          playerVars: {
            disablekb: 1,
          },
        });
      }
    },

    onPlayerStateChange: function (e) {
      if (e.data !== YT.PlayerState.PLAYING) {
        clearInterval(Embeds[`yt_${id}`].interval);
      }
      if (e.data === YT.PlayerState.PLAYING) {
        Embeds[`yt_${id}`].interval = setInterval(function () {
          Embeds[`yt_${id}`].checkPlayer(e);
        }, 2500);
        Embeds[`yt_${id}`].track("videoStart", e);
      }
      if (e.data === YT.PlayerState.PAUSED) {
        Embeds[`yt_${id}`].track("videoPauze", e);
      }
      if (e.data === YT.PlayerState.ENDED) {
        Embeds[`yt_${id}`].done25 = false;
        Embeds[`yt_${id}`].done50 = false;
        Embeds[`yt_${id}`].done75 = false;
        Embeds[`yt_${id}`].track("videoProgress100%", e);
      }
    },

    checkPlayer: function (e) {
      var p = e.target.getCurrentTime();
      var d = e.target.getDuration();
      var c = (p / d) * 100;
      c = Math.round(c);

      if (e.target.isReset) {
        c = 0;
        Embeds[`yt_${id}`].done25 = false;
        Embeds[`yt_${id}`].done50 = false;
        Embeds[`yt_${id}`].done75 = false;
      }

      if (!e.target.completed) {
        if (c >= 25 && !Embeds[`yt_${id}`].done25) {
          Embeds[`yt_${id}`].done25 = true;
          Embeds[`yt_${id}`].track("videoProgress25%", e);
        }
        if (c >= 50 && !Embeds[`yt_${id}`].done50) {
          Embeds[`yt_${id}`].done50 = true;
          Embeds[`yt_${id}`].track("videoProgress50%", e);
        }
        if (c >= 75 && !Embeds[`yt_${id}`].done75) {
          Embeds[`yt_${id}`].done75 = true;
          Embeds[`yt_${id}`].track("videoProgress75%", e);
        }
      }
    },

    track(event, e) {
      var data = e.target.getVideoData();
      var title = data.title;
      var url = "https://www.youtube.com/watch?v=" + data.video_id;
      var _mtm = window._mtm || [];
      _mtm.push({
        event: "interaction.generic.component.videoInteraction",
        meta: {
          action: title + " - " + event + " - " + url,
          label: window.location.href,
        },
      });
    },
  };

  Embeds[`yt_${id}`].init();
}

window.handleYouTubeEmbed = handleYouTubeEmbed;
